import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {from} from 'rxjs';
import jsSHA from 'jssha/dist/sha256';
import { KeycloakProfile } from 'keycloak-js';
import moment from 'moment';
import {ReferenceModel} from '../../core/models/referencemodel';
import {ProfileService} from '../../core/services/profile.service';
import {CompanyStateService} from '../../core/services/company-state.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  isCollapsed = true;
  backendInfo: any = {};
  year = moment().get('year');

  profile: KeycloakProfile;

  avatarUrl: string;

  navItems: NavItem[];
  activeSubNav: NavItem[] = undefined;
  availableCompanies: ReferenceModel[];
  companySelected = false;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private profileService: ProfileService,
    public companyStateService: CompanyStateService,
    private title: Title
  ) {
    this.updateNavItems();
    this.initActiveSubNav();
  }

  ngOnInit(): void {
    from(this.keycloakService.loadUserProfile()).subscribe(
      result => {
        this.profile = result;

        const shaObj = new jsSHA('SHA-256', 'TEXT', {encoding: 'UTF8'});
        shaObj.update(result.username);
        const hash = shaObj.getHash('HEX');
        this.avatarUrl = `https://api.dicebear.com/7.x/pixel-art/svg?seed?${hash}`;
      }
    );

    this.companyStateService.activeCompany.subscribe(next => {
      this.title.setTitle('NEFO 4.0 - ' + next.text);
      this.companySelected = true;
    });

    this.companyStateService.availableCompanies.subscribe(result => this.availableCompanies = result);
  }

  logout() {
    this.keycloakService.logout().then();
  }

  handleNavigationClick(item: NavItem) {
    this.activeSubNav = item.children;
  }

  isActive(item: NavItem) {
    if (item.active !== undefined) {
      return item.active(this.router.url);
    }

    return this.router.url.startsWith(item.routerLink);
  }

  companyChanged(event: ReferenceModel) {
    this.router.navigate(['']);
    this.profileService.switchCompany(event);
  }

  private updateNavItems() {
    this.navItems = [
      {
        label: 'Dashboard',
        routerLink: '/dashboard',
      }, {
        label: 'Rechnungen',
        routerLink: '/invoices',
      }, {
        label: 'Kunden',
        routerLink: '/customers',
      }, {
        label: 'Produkte',
        routerLink: '/products',
      }, {
        label: 'Verwaltung',
        routerLink: '/settings',
      }];
  }

  private initActiveSubNav() {
    const activeItem = this.navItems.find(item => this.isActive(item));
    this.activeSubNav = activeItem ? activeItem.children : undefined;
  }
}

interface NavItem {
  label: string;
  routerLink: string;
  active?: (v: string) => boolean;
  icon?: string;
  children?: NavItem[];
}
